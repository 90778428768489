<template>
  <div class="about">

    <div class="abouthead">
      <h1 id="abouth1">Who we are</h1>
    </div>

    <div class="video-container">

      <video autoplay muted loop id="myVideo">
        <source src="../assets/anm2.mp4" type="video/mp4">
      </video>

    </div>

    <div class="video-descriptors">
      <div class="discvid-main">

        <div class="discvid">
          <h2 class="discvid-h2">More than 35 years of experience</h2>
        </div>

        <div class="vertical-line"></div>

        <div class="discvid">
          <h2 class="discvid-h2">Assurance Services</h2>
        </div>

        <div class="vertical-line"></div>

        <div class="discvid">
          <h2 class="discvid-h2" id="last-vid-h2">Worked with clients from Oil, Steel, Banking, Infrastructure</h2>
        </div>

      </div>
    </div>

    <div class="about-passage">
      
      <div class="about-partners">
          <h2 class="partner-h2">Our Partners</h2>
          <p><ul>
            <li><h4>Ashish Bhatnagar</h4>
                Founder & Partner
                Ashish has over 32 years experience in Assurance Services, System Development & implementation, 
                has Experience in ERP Development and he is a ISA certified Auditor. He is a well- known name for Income Tax & GST 
                consultation. He is the Director of the head office situated at Bengali Market, New Delhi near CP.
                </li>
              <li id="partner-last"><h4>Meeta Bhatnagar</h4>
                Founder & Partner
                Meeta has over 30 years experience in Audit and Assurance Services, accounting, Company law matters 
                and has vast experience in SME sector. She is the Co-Director of the head office situated at Bengali Market, 
                New Delhi near CP.
                </li>
            
            </ul></p>
      </div>

    </div>

    <foot />

  </div>
</template>

<script>
import foot from "../components/footer";
export default {
  components: {
    foot
  },
  data() {
    return{

    }
  }

}
</script>

<style>

 .about {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color:  #2e2e38;
  color: white;
}

.abouthead{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color:  #2e2e38d0;
  text-align: center;
  padding: 50px 100px 20px 100px;
  z-index: 1;
}

.abouthead h1{
  margin-top: 20px;
  padding: 20px 0px 0px 0px;
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
}

/* Video */

.video-container 
{
  width: 100%;
  height: 100vh;
  
}

.video-container video 
{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;  

}


/* Video Descriptors */

.video-descriptors{
  width: 100%;
  display: flex;
  justify-content: center;
}

.discvid-main{
  width: 70%;
  position: absolute;
  top: 50%;
  /* left: 25%; */
  z-index: 2;
  display: flex;

}

.discvid-main .discvid{
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

}

.discvid-main .discvid .discvid-h2{
  width: 75%;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  font-size: 28px;
}

.discvid-main .vertical-line{
  width: 3px;
  height: 25vh;
  background-color: #ffde59;
}

/* About passage  */

.about-passage{
  width: 100%;  
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}

.about-passage .about-partners{
  padding: 50px 0px 50px 0px;
  width: 100%;
  background-color: #2e2e38;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.about-partners .partner-h2{
  /* padding: 50px 0px 50px 20px; */
  /* text-align: center; */
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  /* font-size: 30px; */
}

.about-partners p ul{
  width: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
.about-partners p ul li{
  width: 75%;
  text-align: center;
  margin: auto;
  list-style: none;
  padding: 50px 10px 20px 0px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bolder;
}
.about-partners p ul li h4{
  color: #ffde59;
  font-weight: bolder;
}

#partner-last{
  padding-bottom: 150px;
}

@media screen and (max-width: 767px){

  .abouthead h1{
    margin: 100px 0px -15px 0px;
  }



  .discvid-main{
    width: 100%;
  }

  .discvid-main .discvid .discvid-h2{
    width: 80%;
    font-size: 15px;
  }

  .discvid-main .vertical-line{
    
    background-color: #ffde59;

  }

  .about-partners .partner-h2{
    padding-left: 40px;
  }

}

</style>
